<template>
  <!--Navbar-->
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" href="/"
      ><img src="@/assets/images/logo.png" style="height: 32px"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <router-link to="/" class="nav-item nav-link">Ana Sayfa</router-link>
        <router-link
          :to="'/live/' + currentEvent.defaultStage"
          class="nav-item nav-link"
          >Canlı Yayın</router-link
        >
        <router-link to="/agenda" class="nav-item nav-link"
          >Bilimsel Program</router-link
        >
        <!--<router-link to="/stand" class="nav-item nav-link">Stand</router-link>-->

        <router-link to="/expo" class="nav-item nav-link"
          >Stand Alanı</router-link
        >

        <!-- eslint-disable max-len -->
        <button class="btn btn-light" @click="logout">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-box-arrow-right"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
            />
            <path
              fill-rule="evenodd"
              d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
            />
          </svg>
          Çıkış
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      logoutAction: 'people/logout',
    }),
    logout() {
      this.logoutAction();
    },
  },
};
</script>

<style>
</style>
