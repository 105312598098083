<template>
  <!--Sponsor-->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="box p-4">
            <h2 class="title text-center mb-4">Stand Alanı</h2>
            <div class="row" style="justify-content: center">
              <div class="col-md-6 mb-4" style="text-center">
                <router-link class="booth-logo" to="/booth/Abdiibrahim">
                  <img src="/assets/abdiibrahim/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-4" style="text-center">
                <router-link class="booth-logo" to="/booth/Bilim">
                  <img src="/assets/bilim/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-4" style="text-center">
                <router-link class="booth-logo" to="/booth/Deva">
                  <img src="/assets/deva/stand_logo.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-4" style="text-center">
                <router-link class="booth-logo" to="/booth/Sanofi">
                  <img src="/assets/sanofi/stand_logo_1.png" alt="" />
                  <button class="btn btn-secondary">Tıklayınız</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.booth-logo {
  display: block;
  border-radius: 8px;
  box-shadow: 0 0 2px 1px rgba(154, 160, 185, 0.2);
  &:hover {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }
  img {
    border-radius: 8px;
  }
}
</style>
